import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import ArrowBackIcon from '../../../Assets/arrow-left-solid.svg';
import '../CSS/SignUpPage.css';
import ConfettiExplosion from 'react-confetti-explosion';

const LogInPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');  
    const [password, setPassword] = useState('');

    const handleLogIn = async (event) => {
        event.preventDefault();
        setLoading(true);
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            user.getIdTokenResult()
                .then(idTokenResult => {
                    if (idTokenResult.claims.role === 'brands') {
                        setIsSubmitted(true);
                        navigate('/dashboard'); // Navigate to brand dashboard
                    } else {
                        setError('Access Denied: You are not authorized as a brand.');
                    }
                })
                .catch(error => {
                    setError(error.message);
                });
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        const emailFromQuery = new URLSearchParams(window.location.search).get("email");
        setEmail(emailFromQuery || '');
    }, []);

    const goBack = () => {
        navigate('/');
    }

    return (
        <div className="signup">
            {!isSubmitted ? (
                <>
                    {error && <p className="error">{error}</p>}
                    {!loading && (
                        <button className="back-button" onClick={goBack}>
                            <img src={ArrowBackIcon} alt="Back" />
                        </button>
                    )}
                    <h1>Hola de nuevo!</h1>
                    <h5>Inicia sesión para continuar</h5>
                    <form onSubmit={handleLogIn}>
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                        <input 
                            type='password' 
                            name='password' 
                            placeholder='Contraseña' 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required 
                        />
                        <button type="submit" disabled={loading}>
                            {loading ? "Loading..." : "Iniciar sesión"}
                        </button>
                    </form>
                </>
            ) : (
                <div>
                    <h2>¡Gracias por iniciar sesión!</h2>
                    <ConfettiExplosion />
                    <p>Redireccionando al dashboard...</p>
                </div>
            )}
        </div>
    );
};

export default LogInPage;
