import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/SalesPage.css'; // Ensure this path is correct
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, update, writeBatch } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import ImageSizeSlider from './ImageSizeSlider'; // Ensure this path is correct
import Sidebar from './Sidebar'; // Import the Sidebar component
import {auth, db} from '../../../firebase'; // Adjust imports based on your setup
import Logo from '../../../Assets/wai-logo-black.png';
import '../CSS/HomePage.css';
import '../../../spinner.css';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify'; // Add this import at the top of the file
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { PDFDocument, rgb } from 'pdf-lib';

const SalesPage = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState('ventas');
  const [sales, setSales] = useState([]);
  const [expandedSaleId, setExpandedSaleId] = useState(null);
  const [itemData, setItemData] = useState({});
  const [imageSize, setImageSize] = useState(100); // State for image size
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('ajustes');
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [printFormat, setPrintFormat] = useState('zebra'); // 'zebra' or 'a4'
  const [showPrintOptions, setShowPrintOptions] = useState(false);
  const [shippingFilter, setShippingFilter] = useState('all'); // 'all', 'pending', 'printed', 'shipped'
  const [filteredSales, setFilteredSales] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setShowLeftSidebar(false);
      } else {
        setShowLeftSidebar(true);
      }
    };

    window.addEventListener('resize', handleResize);
    // Set initial state based on window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = async () => {
    closeModal();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {

    const fetchSalesData = async () => {
      const user = auth.currentUser;
      setLoading(true);
      if (!user) {
        console.error("User not authenticated");
        return;
      }

      try {
        const profileQuery = query(collection(db, 'brands_profile_test'), where('uid', '==', user.uid));
        const profileSnapshot = await getDocs(profileQuery);
        setProfileData(profileSnapshot.docs[0].data());
        console.log(user.uid);
        const salesQuery = query(collection(db, 'brand_sales'), where('brand_uid', '==', user.uid));
        const salesSnapshot = await getDocs(salesQuery);

        const salesData = await Promise.all(salesSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const date = data.updated_at ? data.updated_at.toDate() : null;
          console.log("sale data");
          // Fetch user data for each sale
          let userData = null;
          if (data.user_id) {
            const userQuery = query(collection(db, 'users'), where('user_id', '==', data.user_id));
            const userSnapshot = await getDocs(userQuery);
            if (!userSnapshot.empty) {
              userData = userSnapshot.docs[0].data();
            }
          }

          return {
            id: doc.id,
            ...data,
            date,
            user: userData,
          };
        }));

        setSales(salesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sales data: ", error);
        setLoading(false);
      }
    };

    const authUnsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchSalesData();
      }
    });

    return () => authUnsubscribe();
  }, [auth, db, navigate]);

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(`/${path}`);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const fetchItemData = async (saleId, cartItems) => {
    try {
      const itemQueries = cartItems.map(item => getDocs(query(collection(db, 'items_test'), where('wearly_item_id', '==', item.wearly_item_id))));
      const itemSnapshots = await Promise.all(itemQueries);

      const items = itemSnapshots.map(snapshot => snapshot.docs[0].data());
      setItemData(prevState => ({ ...prevState, [saleId]: items }));
    } catch (error) {
      console.error("Error fetching item data: ", error);
    }
  };

  const toggleDropdown = (saleId, cartItems) => {
    if (expandedSaleId === saleId) {
      setExpandedSaleId(null);
    } else {
      setExpandedSaleId(saleId);
      if (!itemData[saleId]) {
        fetchItemData(saleId, cartItems);
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Link copiado al portapapeles!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      console.error('Error al copiar al portapapeles: ', err);
      toast.error('Error al copiar el link. Por favor, inténtalo de nuevo.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const fetchPDF = async (url) => {
    try {
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const arrayBuffer = await response.arrayBuffer();
      if (arrayBuffer.byteLength === 0) {
        throw new Error('Received empty PDF');
      }
      
      return arrayBuffer;
    } catch (error) {
      console.error('Error fetching PDF:', error);
      throw error;
    }
  };

  const handlePrintLabels = async (format) => {
    try {
      setLoading(true);
      const selectedLabels = selectedSales
        .map(saleId => sales.find(s => s.id === saleId))
        .filter(sale => sale && sale.shipping_label);

      if (selectedLabels.length === 0) {
        toast.error('No hay etiquetas de envío seleccionadas');
        setLoading(false);
        return;
      }

      // Create a new PDF document
      const mergedPdf = await PDFDocument.create();

      if (format === 'zebra') {
        console.log('Processing in Zebra format');
        // For Zebra format: Simply merge PDFs sequentially
        for (const sale of selectedLabels) {
          try {
            const labelBytes = await fetchPDF(sale.shipping_label);
            const labelPdf = await PDFDocument.load(labelBytes);
            
            const pages = await mergedPdf.copyPages(labelPdf, labelPdf.getPageIndices());
            pages.forEach(page => {
              mergedPdf.addPage(page);
            });
          } catch (error) {
            console.error('Error processing Zebra label:', error);
            toast.error(`Error al procesar etiqueta ${sale.purchase_uuid}`);
          }
        }
      } else {
        console.log('Processing in A4 format');
        // For A4 format: Up to 4 labels per A4 page
        const labelsPerPage = 4;
        const totalPages = Math.ceil(selectedLabels.length / labelsPerPage);

        for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
          try {
            // Create a new A4 page
            const a4Page = mergedPdf.addPage([595.28, 841.89]); // A4 dimensions in points

            // Get labels for this page (up to 4)
            const pageLabels = selectedLabels.slice(pageIndex * labelsPerPage, (pageIndex + 1) * labelsPerPage);

            // Calculate dimensions for a single label space (2x2 grid)
            const margin = 40;
            const gutter = 20;
            const availableWidth = (595.28 - (margin * 2) - gutter) / 2;  // Width for each label
            const availableHeight = (841.89 - (margin * 2) - gutter) / 2; // Height for each label

            // Define positions for 2x2 grid
            const positions = [
              { x: margin, y: 841.89 - margin - availableHeight },           // top-left
              { x: margin + availableWidth + gutter, y: 841.89 - margin - availableHeight },  // top-right
              { x: margin, y: margin },                                      // bottom-left
              { x: margin + availableWidth + gutter, y: margin }            // bottom-right
            ];

            // Process each label for this page
            for (let i = 0; i < pageLabels.length; i++) {
              try {
                const sale = pageLabels[i];
                const labelBytes = await fetchPDF(sale.shipping_label);
                const sourcePdf = await PDFDocument.load(labelBytes);
                
                if (sourcePdf.getPageCount() === 0) {
                  console.error('Source PDF has no pages');
                  continue;
                }

                // Embed the PDF page
                const [embeddedPage] = await mergedPdf.embedPdf(sourcePdf, [0]);
                
                if (!embeddedPage) {
                  console.error('Failed to embed page');
                  continue;
                }

                // Get source dimensions
                const sourceWidth = embeddedPage.width;
                const sourceHeight = embeddedPage.height;

                // Calculate scale to fit in the grid cell while maintaining aspect ratio
                const scale = Math.min(
                  availableWidth / sourceWidth,
                  availableHeight / sourceHeight
                );

                // Calculate dimensions for this label
                const scaledWidth = sourceWidth * scale;
                const scaledHeight = sourceHeight * scale;

                // Center the label within its grid cell
                const position = positions[i];
                const x = position.x + (availableWidth - scaledWidth) / 2;
                const y = position.y + (availableHeight - scaledHeight) / 2;

                // Draw the label
                a4Page.drawPage(embeddedPage, {
                  x,
                  y,
                  width: scaledWidth,
                  height: scaledHeight,
                });

                // Add border around the label
                a4Page.drawRectangle({
                  x: x - 1,
                  y: y - 1,
                  width: scaledWidth + 2,
                  height: scaledHeight + 2,
                  borderColor: rgb(0.8, 0.8, 0.8),
                  borderWidth: 1,
                  opacity: 0.5,
                });

              } catch (drawError) {
                console.error('Error drawing label:', drawError);
                continue;
              }
            }
          } catch (error) {
            console.error('Error processing A4 page:', error);
            toast.error('Error al procesar la página');
          }
        }
      }

      // Save and download the final PDF
      const mergedPdfBytes = await mergedPdf.save();
      const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `shipping-labels-${format}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      // After successful printing, update shipping state
      await updateShippingState(selectedSales, 'printed');
      
      setLoading(false);
    } catch (error) {
      console.error('Error in handlePrintLabels:', error);
      toast.error('Error al procesar las etiquetas. Por favor, inténtalo de nuevo.');
      setLoading(false);
    }
  };

  const handleSaleSelection = (saleId) => {
    setSelectedSales(prev => {
      if (prev.includes(saleId)) {
        return prev.filter(id => id !== saleId);
      } else {
        return [...prev, saleId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // Select only pending sales (those without shipping_state)
      const pendingSales = sales
        .filter(sale => !sale.shipping_state && sale.shipping_label)
        .map(sale => sale.id);
      setSelectedSales(pendingSales);
    } else {
      setSelectedSales([]);
    }
  };

  const updateShippingState = async (saleIds, newState) => {
    try {
      const batch = writeBatch(db);
      
      saleIds.forEach(saleId => {
        const saleRef = doc(db, 'brand_sales', saleId);
        batch.update(saleRef, { shipping_state: newState });
      });

      await batch.commit();
      
      // Update local state
      setSales(prevSales => 
        prevSales.map(sale => 
          saleIds.includes(sale.id) 
            ? { ...sale, shipping_state: newState }
            : sale
        )
      );

      toast.success(`Estado de envío actualizado a: ${newState}`);
      // Unselect all sales after updating
      setSelectedSales([]);
    } catch (error) {
      console.error('Error updating shipping state:', error);
      toast.error('Error al actualizar el estado de envío');
    }
  };

  useEffect(() => {
    const filterSales = () => {
      if (shippingFilter === 'all') {
        setFilteredSales(sales);
      } else if (shippingFilter === 'pending') {
        setFilteredSales(sales.filter(sale => !sale.shipping_state));
      } else {
        setFilteredSales(sales.filter(sale => sale.shipping_state === shippingFilter));
      }
    };

    filterSales();
  }, [sales, shippingFilter]);

  const groupSalesByShippingState = () => {
    const pending = filteredSales.filter(sale => !sale.shipping_state);
    const printed = filteredSales.filter(sale => sale.shipping_state === 'printed');
    const shipped = filteredSales.filter(sale => sale.shipping_state === 'shipped');

    return { pending, printed, shipped };
  };

  return (
    <div className="content">
      <div className="home-page-container">
        <div className="main-content">
          <div className="user-profile-container">
            { !showLeftSidebar && <div className="user-profile-sidebar">
              <div className='logo' style={{width:'140px', height:'60px', alignItems:'left', justifyContent:'left'}}>
                <img src={Logo} className='logo-img' style={{ width: '100%', height: '100%',alignItems:'left', justifyContent:'left', objectFit: 'contain', padding:'0px' }} />
              </div>
              <ul className="user-profile-sidebar-links">
                <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                <li onClick={openModal}>Cerrar Sesion</li>
              </ul>
            </div>}
            <div className="user-profile-settings">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 style={{ fontWeight: 900 }}>Tus Ventas</h1>
              </div>
              <div className="sales-list">
                {sales.length > 0 && (
                  <>
                    <div style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px 0',
                      borderBottom: '1px solid #eee',
                      marginBottom: '10px'
                    }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          style={{ 
                            width: '20px', 
                            height: '20px',
                            cursor: 'pointer'
                          }}
                        />
                        <span style={{ fontSize: '14px', color: '#666' }}>Seleccionar Todas</span>
                      </div>
                      
                      {selectedSales.length > 0 && (
                        <div style={{ position: 'relative' }}>
                          <button 
                            onClick={() => setShowPrintOptions(!showPrintOptions)}
                            style={{
                              backgroundColor: 'black',
                              color: 'white',
                              padding: '10px 20px',
                              borderRadius: '7px',
                              cursor: loading ? 'not-allowed' : 'pointer',
                              border: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              opacity: loading ? 0.7 : 1
                            }}
                          >
                            {loading ? 'Procesando...' : `Imprimir Etiquetas (${selectedSales.length})`}
                            <span style={{ marginLeft: '5px' }}>▼</span>
                          </button>
                          
                          {showPrintOptions && (
                            <div style={{
                              position: 'absolute',
                              top: '100%',
                              right: 0,
                              backgroundColor: 'white',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              zIndex: 1000,
                              marginTop: '5px',
                              minWidth: '150px'
                            }}>
                              <div 
                                onClick={async () => {
                                  setShowPrintOptions(false);
                                  setPrintFormat('zebra');
                                  await new Promise(resolve => setTimeout(resolve, 100));
                                  handlePrintLabels('zebra');
                                }}
                                className="print-option"
                                style={{
                                  padding: '10px 15px',
                                  cursor: 'pointer',
                                  borderBottom: '1px solid #eee'
                                }}
                              >
                                Formato Zebra
                              </div>
                              <div 
                                onClick={async () => {
                                  setShowPrintOptions(false);
                                  setPrintFormat('a4');
                                  await new Promise(resolve => setTimeout(resolve, 100));
                                  handlePrintLabels('a4');
                                }}
                                className="print-option"
                                style={{
                                  padding: '10px 15px',
                                  cursor: 'pointer'
                                }}
                              >
                                Formato A4
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Filter bubbles */}
                    <div style={{
                      display: 'flex',
                      gap: '10px',
                      marginBottom: '20px',
                      flexWrap: 'wrap'
                    }}>
                      {['all', 'pending', 'printed', 'shipped'].map((filter) => (
                        <button
                          key={filter}
                          onClick={() => setShippingFilter(filter)}
                          style={{
                            padding: '8px 16px',
                            borderRadius: '20px',
                            border: '1px solid #ddd',
                            backgroundColor: shippingFilter === filter ? 'black' : 'white',
                            color: shippingFilter === filter ? 'white' : 'black',
                            cursor: 'pointer',
                            fontSize: '14px',
                            transition: 'all 0.2s ease',
                            ':hover': {
                              backgroundColor: shippingFilter === filter ? 'black' : '#f5f5f5'
                            }
                          }}
                        >
                          {filter === 'all' && 'Todos'}
                          {filter === 'pending' && 'Pendientes'}
                          {filter === 'printed' && 'Impresos'}
                          {filter === 'shipped' && 'Enviados'}
                        </button>
                      ))}
                    </div>
                  </>
                )}

                {sales.length === 0 ? (
                  <>
                    <p>Aún no tienes ventas.<br/>Comparte tu perfil para ayudar a tus clientes a explorar tu tienda de la mejor manera.</p>
                    <button 
                      onClick={() => copyToClipboard(`https://wearlyapp.com/brand/${profileData.wearly_brand_id}`)}
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '10px 50px',
                        borderRadius: '7px',
                        cursor: 'pointer',
                        border: 'none'
                      }}
                    >
                      Comparti tu Tienda!
                    </button>
                  </>
                ) : (
                  <>
                    {/* Pending Sales */}
                    {groupSalesByShippingState().pending.length > 0 && (
                      <>
                        <div style={{ 
                          borderBottom: '2px solid #eee', 
                          margin: '20px 0', 
                          paddingBottom: '10px',
                          fontWeight: 'bold',
                          color: '#666'
                        }}>
                          Pendientes
                        </div>
                        {groupSalesByShippingState().pending.map(sale => (
                          <div key={sale.id} className="sale-item">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <input
                                type="checkbox"
                                checked={selectedSales.includes(sale.id)}
                                onChange={() => handleSaleSelection(sale.id)}
                                style={{ 
                                  width: '20px', 
                                  height: '20px',
                                  cursor: 'pointer'
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <div className="sale-header">
                                  <span className="sale-id">#{sale.purchase_uuid}</span>
                                  <span className="sale-date">{sale.date ? sale.date.toLocaleDateString() : 'N/A'}</span>
                                </div>
                                <div className="sale-body">
                                  <span className="sale-client">{`${sale.user.first_name} ${sale.user.last_name}`}</span>
                                  <span className="sale-total">${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</span>
                                </div>
                                <div className="sale-footer">
                                  <span className={`status ${sale.payment_status ? sale.payment_status.toLowerCase() : ''}`}>
                                    {sale.payment_status}
                                  </span>
                                  <a className="view-items" onClick={() => toggleDropdown(sale.id, sale.cart_items)}>
                                    {expandedSaleId === sale.id ? 'Ver menos' : 'Ver mas'}
                                  </a>
                                </div>
                                {expandedSaleId === sale.id && (
                                  <div className="dropdown-content">
                                    {itemData[sale.id] && itemData[sale.id].length > 0 ? (
                                      <>
                                        <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Productos:</p>
                                        <ul style={{ listStyleType: 'disc', marginLeft: '20px', paddingLeft: '20px', marginTop: '0px' }}>
                                          {itemData[sale.id].map((item, index) => (
                                            <li key={index} style={{ listStyleType: 'disc', marginBottom: '10px' }}>
                                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.name}
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Dirección:</p>
                                          <p style={{ marginLeft: '10px', marginTop: '0px' }}>{[
                                            sale.user.user_address.user_address,
                                            sale.user.user_address.street_number,
                                            sale.user.user_address.apt_number && `Apt ${sale.user.user_address.apt_number}`,
                                            sale.user.user_address.floor && `Floor ${sale.user.user_address.floor}`,
                                            sale.user.user_address.neighborhood,
                                            sale.user.user_address.city,
                                            sale.user.user_address.province,
                                            sale.user.user_address.postal_code
                                          ].filter(Boolean).join(', ')}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Email:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.email}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Phone:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.phone}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Sub Total:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.total_products ? sale.total_products.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Shipping:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.shipping_cost ? sale.shipping_cost.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Comisión:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>- ${sale.amount * 0.05 ? (sale.amount * 0.05).toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total a Liquidar:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? (sale.amount - (sale.amount * 0.05)).toLocaleString() : 'N/A'}</p>
                                        </div>
                                      </>
                                    ) : (
                                      <p>No products found for this sale.</p>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {/* Printed Sales */}
                    {groupSalesByShippingState().printed.length > 0 && (
                      <>
                        <div style={{ 
                          borderBottom: '2px solid #eee', 
                          margin: '20px 0', 
                          paddingBottom: '10px',
                          fontWeight: 'bold',
                          color: '#666'
                        }}>
                          Impresos
                        </div>
                        {groupSalesByShippingState().printed.map(sale => (
                          <div key={sale.id} className="sale-item">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <input
                                type="checkbox"
                                checked={selectedSales.includes(sale.id)}
                                onChange={() => handleSaleSelection(sale.id)}
                                style={{ 
                                  width: '20px', 
                                  height: '20px',
                                  cursor: 'pointer'
                                }}
                              />
                              <div style={{ flex: 1 }}>
                                <div className="sale-header">
                                  <span className="sale-id">#{sale.purchase_uuid}</span>
                                  <span className="sale-date">{sale.date ? sale.date.toLocaleDateString() : 'N/A'}</span>
                                </div>
                                <div className="sale-body">
                                  <span className="sale-client">{`${sale.user.first_name} ${sale.user.last_name}`}</span>
                                  <span className="sale-total">${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</span>
                                </div>
                                <div className="sale-footer">
                                  <span className={`status ${sale.payment_status ? sale.payment_status.toLowerCase() : ''}`}>
                                    {sale.payment_status}
                                  </span>
                                  <a className="view-items" onClick={() => toggleDropdown(sale.id, sale.cart_items)}>
                                    {expandedSaleId === sale.id ? 'Ver menos' : 'Ver mas'}
                                  </a>
                                </div>
                                {expandedSaleId === sale.id && (
                                  <div className="dropdown-content">
                                    {itemData[sale.id] && itemData[sale.id].length > 0 ? (
                                      <>
                                        <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Productos:</p>
                                        <ul style={{ listStyleType: 'disc', marginLeft: '20px', paddingLeft: '20px', marginTop: '0px' }}>
                                          {itemData[sale.id].map((item, index) => (
                                            <li key={index} style={{ listStyleType: 'disc', marginBottom: '10px' }}>
                                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.name}
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Dirección:</p>
                                          <p style={{ marginLeft: '10px', marginTop: '0px' }}>{[
                                            sale.user.user_address.user_address,
                                            sale.user.user_address.street_number,
                                            sale.user.user_address.apt_number && `Apt ${sale.user.user_address.apt_number}`,
                                            sale.user.user_address.floor && `Floor ${sale.user.user_address.floor}`,
                                            sale.user.user_address.neighborhood,
                                            sale.user.user_address.city,
                                            sale.user.user_address.province,
                                            sale.user.user_address.postal_code
                                          ].filter(Boolean).join(', ')}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Email:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.email}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Phone:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.phone}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Sub Total:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.total_products ? sale.total_products.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Shipping:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.shipping_cost ? sale.shipping_cost.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Comisión:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>- ${sale.amount * 0.05 ? (sale.amount * 0.05).toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</p>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                          <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total a Liquidar:</p>
                                          <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? (sale.amount - (sale.amount * 0.05)).toLocaleString() : 'N/A'}</p>
                                        </div>
                                      </>
                                    ) : (
                                      <p>No products found for this sale.</p>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {/* Shipped Sales */}
                    {groupSalesByShippingState().shipped.length > 0 && (
                      <>
                        <div style={{ 
                          borderBottom: '2px solid #eee', 
                          margin: '20px 0', 
                          paddingBottom: '10px',
                          fontWeight: 'bold',
                          color: '#666'
                        }}>
                          Enviados
                        </div>
                        {groupSalesByShippingState().shipped.map(sale => (
                    <div key={sale.id} className="sale-item">
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <input
                          type="checkbox"
                          checked={selectedSales.includes(sale.id)}
                          onChange={() => handleSaleSelection(sale.id)}
                          style={{ 
                            width: '20px', 
                            height: '20px',
                            cursor: 'pointer'
                          }}
                        />
                        <div style={{ flex: 1 }}>
                          <div className="sale-header">
                            <span className="sale-id">#{sale.purchase_uuid}</span>
                            <span className="sale-date">{sale.date ? sale.date.toLocaleDateString() : 'N/A'}</span>
                          </div>
                          <div className="sale-body">
                            <span className="sale-client">{`${sale.user.first_name} ${sale.user.last_name}`}</span>
                            <span className="sale-total">${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</span>
                          </div>
                          <div className="sale-footer">
                            <span className={`status ${sale.payment_status ? sale.payment_status.toLowerCase() : ''}`}>
                              {sale.payment_status}
                            </span>
                            <a className="view-items" onClick={() => toggleDropdown(sale.id, sale.cart_items)}>
                              {expandedSaleId === sale.id ? 'Ver menos' : 'Ver mas'}
                            </a>
                          </div>
                          {expandedSaleId === sale.id && (
                            <div className="dropdown-content">
                              {itemData[sale.id] && itemData[sale.id].length > 0 ? (
                                <>
                                  <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Productos:</p>
                                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', paddingLeft: '20px', marginTop: '0px' }}>
                                    {itemData[sale.id].map((item, index) => (
                                      <li key={index} style={{ listStyleType: 'disc', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          {item.name}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Dirección:</p>
                                    <p style={{ marginLeft: '10px', marginTop: '0px' }}>{[
                                      sale.user.user_address.user_address,
                                      sale.user.user_address.street_number,
                                      sale.user.user_address.apt_number && `Apt ${sale.user.user_address.apt_number}`,
                                      sale.user.user_address.floor && `Floor ${sale.user.user_address.floor}`,
                                      sale.user.user_address.neighborhood,
                                      sale.user.user_address.city,
                                      sale.user.user_address.province,
                                      sale.user.user_address.postal_code
                                    ].filter(Boolean).join(', ')}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Email:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.email}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Phone:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>{sale.user.phone}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Sub Total:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.total_products ? sale.total_products.toLocaleString() : 'N/A'}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Shipping:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.shipping_cost ? sale.shipping_cost.toLocaleString() : 'N/A'}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Comisión:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>- ${sale.amount * 0.05 ? (sale.amount * 0.05).toLocaleString() : 'N/A'}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? sale.amount.toLocaleString() : 'N/A'}</p>
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <p style={{ marginTop: '0px', fontWeight: 'bold' }}>Total a Liquidar:</p>
                                    <p style={{ marginTop: '0px', marginLeft: '10px' }}>${sale.amount ? (sale.amount - (sale.amount * 0.05)).toLocaleString() : 'N/A'}</p>
                                  </div>
                                </>
                              ) : (
                                <p>No products found for this sale.</p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Sign Out"
        className="user-profile-modal-sign-out"
        overlayClassName="user-profile-modal-sign-out-overlay"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000
          }
        }}
      >
        <div style={{marginTop: 0, backgroundColor: '#FFF', padding: '20px', borderRadius: '5px'}} className="user-profile-modal-sign-out-content">
          <h2 className="user-profile-modal-sign-out-header">Confirm Sign Out</h2>
          <p className="user-profile-modal-sign-out-text">Are you sure you want to sign out?</p>
          <div className="user-profile-modal-sign-out-buttons">
            <button onClick={handleSignOut} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>Yes</button>
            <button onClick={closeModal} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>No</button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default SalesPage;
